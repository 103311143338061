import { useFormik } from 'formik';
import React from 'react'
import { toast } from 'react-toastify';
import { addSurvey } from '../../services/data.service';
import { FiSend } from 'react-icons/fi';

function Survey() {

    const formik = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            description: '',
            profession: '',
            job: '',
            phone: '',
            address: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.fname) {
                errors.fname = 'Эцэг/эхийн нэр оруулна уу.';
            }

            if (!data.lname) {
                errors.lname = 'Өөрийн нэрээ оруулна уу.';
            }

            if (!data.phone) {
                errors.phone = 'Утасны дугаараа оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("description", data.description);
            formData.append("fname", data.fname);
            formData.append("lname", data.lname);
            formData.append("profession", data.profession);
            formData.append("job", data.job);
            formData.append("phone", data.phone);
            formData.append('address', data.address);

            addSurvey(formData)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                    formik.resetForm();
                }else{
                    toast.error(res.message);
                }
            });
        }
    });

  return (
    <>
        <section className="hero-4 m-3 pb-1 pt-3">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 text-center">
                        <h1 className="hero-title mb-0">Судалгаа</h1>
                        <p className="mb-4 fs-17 text-muted">Та судалгаанд идэвхтэй хамрагдана уу</p>
                    </div>
                </div>
            </div>
            <div className="shape bottom">
                <svg width="1440px" height="40px" viewBox="0 0 1440 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="shape-b" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="curve" fill="#fff">
                            <path
                                d="M0,30.013 C239.659,10.004 479.143,0 718.453,0 C957.763,0 1198.28,10.004 1440,30.013 L1440,40 L0,40 L0,30.013 Z"
                                id="Path"></path>
                        </g>
                    </g>
                </svg>
            </div>
        </section>
        <section className="section pb-md-3 position-relative">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="card shadow-none">
                            <div className="card-body p-xl-5 p-0">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="fname" className="fw-medium form-label">Овог <span
                                                        className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="fname" name='fname' placeholder="Овог" 
                                                    value={formik.values.fname}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('fname', e.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="lname" className="fw-medium form-label">Нэр <span
                                                        className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="lname" name='lname' placeholder="Нэр"  
                                                    value={formik.values.lname}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('lname', e.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="profession" className="fw-medium form-label">Мэргэжил <span
                                                        className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="profession" name='profession' placeholder="Мэргэжил" 
                                                    value={formik.values.profession}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('profession', e.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="job" className="fw-medium form-label">Эрхэлж буй ажил <span
                                                        className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="job" name='job' placeholder="Эрхэлж буй ажил"  
                                                    value={formik.values.job}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('job', e.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="phone" className="fw-medium form-label">Утас <span
                                                        className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="phone" name='phone' placeholder="Утас"
                                                value={formik.values.phone}
                                                onChange={(e) => {
                                                    formik.setFieldValue('phone', e.target.value);
                                                }} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="address" className="fw-medium form-label">Хаяг <span
                                                        className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="address" name='address' placeholder="Хаяг"
                                                value={formik.values.address}
                                                onChange={(e) => {
                                                    formik.setFieldValue('address', e.target.value);
                                                }} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="description" className="form-label">Дэлгэрэнгүй тайлбар <span
                                                    className="text-danger">*</span></label>
                                                <textarea className="form-control" id="description" name='description' rows="4"
                                                    placeholder="Сүхбаатар аймгийн Наран сумтай ямар холбоотой талаар бичнэ үү..."
                                                    value={formik.values.description}
                                                onChange={(e) => {
                                                    formik.setFieldValue('description', e.target.value);
                                                }}></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-primary">
                                                <FiSend />&nbsp;
                                                Илгээх
                                               
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default Survey