import React, { useState } from 'react'
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import {encode} from 'html-entities';
import { toast } from 'react-toastify';
import { QUILL_TOOLBAR } from '../../../config/Utilities';
import { addMeta } from '../../../services/data.service';
import { useNavigate} from "react-router-dom";
import { FcCheckmark } from 'react-icons/fc';

function MetaAdd() {


    let navigate = useNavigate();

    const renderHeader = () => {
        return QUILL_TOOLBAR()
    }

    const header = renderHeader();

    const formik = useFormik({
        initialValues: {
            name: '',
            key: '',
            description: ''
            //isFeatured: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Нэр оруулна уу.';
            }

            if (!data.key) {
                errors.key = 'Түлхүүр үг оруулна уу.';
            }

            if (!data.description) {
                errors.description = 'Дэлгэрэнгүй тайлбар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("description", encode(data.description));
            formData.append("name", data.name);
            formData.append("key", data.key);

            addMeta(formData)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                    formik.resetForm();
                }else{
                    toast.error(res.message);
                }
            });
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
          <div className='container'>
          <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Ерөнхий мэдээлэл шинээр бүртгэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate(-1)} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                  <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                            <div className="form-group p-fluid">
                                <label>Нэр</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={(e) => {
                                        formik.setFieldValue('name', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('name') })}
                                />
                                
                         
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Түлхүүр үг</label>
                                <InputText
                                    id="key"
                                    name="key"
                                    value={formik.values.title}
                                    onChange={(e) => {
                                        formik.setFieldValue('key', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('key') })}
                                />
                                {getFormErrorMessage('key')}
                            </div>
                            <div className='form-group mt-1'>
                                <label>Дэлгэрэнгүй тайлбар</label>
                                <Editor value={formik.values.description} headerTemplate={header} className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                                onTextChange={(e) => formik.setFieldValue('description',e.htmlValue)} style={{ height: '320px' }} />
                                {getFormErrorMessage('description')}
                            </div>
                            
                            <button className='btn btn-primary mt-2' type='submit'><FcCheckmark /> Хадгалах</button>
                        </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default MetaAdd