

import React, { useEffect, useState, useMemo }  from 'react'
import { fetchMemberPagination, deleteMember, fetchMemberTypes } from '../../../services/data.service';
import DataTable from "react-data-table-component";
import { ACCESS_LEVELS, CHECK_PERMISSION, PAGINATION_OPTION } from '../../../config/Utilities';
import { FaEdit, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from 'react-toastify';

export default function MemberList() {


    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [types, setTypes] = useState([])
    const [type, setType] = useState('0')

    const [filter, setFilter] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        fetchMemberTypes().then((r) => {
            setTypes(r.data)
        })

        fetchList(1)    

    },[])

    const fetchList = async (page, size = perPage) => {
        setLoading(true);
    
        fetchMemberPagination(page, size, filter, type)
        .then((r) => {
            console.log(r)
            setList(r.data.list);
            setTotalRows(r.data.totalItems);
            setLoading(false);
        })

        
    };

    const handleEdit = (id) => {
        navigate(`/h/member/edit/${id}`);
    }

    const handleDelete = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h2>НИЙТЛЭЛ УСТГАХ</h2>
                    <p>ТА ЭНЭ ҮЙЛДЛИЙГ ХИЙХИЙГ ЗӨВШӨӨРЧ БАЙНА УУ?</p>
                    <button className='btn-dismiss' onClick={onClose}>Буцах</button>
                    <button className='btn-confirm'
                      onClick={() => {
                        deleteMember(id)
                        .then((res)=>{
                            if(res.success){
                                setList(list.filter(item => item.uuid !== id));
                                toast.success(res.message);
                            }else{
                                toast.error(res.message);
                            }
                        });
                        onClose();
                      }}
                    >
                      Тийм
                    </button>
                  </div>
                );
            }
        });
        
    }

    const buttonEditTemplate = (row) => {

        return (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator])) 
            ?
            <button type='button' className="btn btn-sm btn-warning" onClick={() => {handleEdit(row.uuid)}}><FaEdit /></button>
        : null

    }

    const buttonRemoveTemplate = (row) => {
        return (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator])) 
            ?
            <button type='button' className="btn btn-sm btn-danger" onClick={() => {handleDelete(row.uuid)}}><FaEdit /></button>
        : null
    }

    const columns = useMemo(() => [
        {
            name: "Овог, нэр",
            selector: row => row.first_name + " " + row.last_name,
            sortable: true
        },
        {
            name: "Албан тушаал",
            selector: row => row.position,
            sortable: true
        },
        {
            name: "Утас",
            selector: row => row.phone_number,
            sortable: true
        },
        {
            name: "Төрөл",
            selector: row => row.member_type?.title,
            sortable: true
        },
        {
            name: "Засварлах",
            cell: row => buttonEditTemplate(row)
        },
        {
            name: "Устгах",
            cell: row => buttonRemoveTemplate(row)
        }
      ])

      const handlePageChange = page => {
        fetchList(page);
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = async (newPerPage, page) => {
        fetchList(page, newPerPage);
        setPerPage(newPerPage);
      };

      const handleSearch = () => {

        setCurrentPage(1)
        fetchList(1)
    }


  return (
    <>
        <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <div className='container'>
        <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col">
                            <h4 className="mb-3 mt-0 fs-16">Нутгийн зөвлөлийн гишүүд</h4>
                        </div>
                        <div className="col-auto text-end">
                            {
                                (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator])) 
                                ? 
                                <>
                                    <a href="/h/member/add" className="fw-semibold text-primary fs-13">Шинээр нэмэх <i
                                        className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                                </>
                                : 
                                "" 
                            }
                            
                        </div>
                    </div>

     
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className='input-group'>
                                        <select className='form-control' value={type} onChange={(e) => {
                                            setType(e.target.value)
                                        }}>
                                            <option key={"0"} value="0">Бүгд</option>
                                            {
                                                types.map((m) => (
                                                    <option key={m.uuid} value={m.uuid}>{m.title}</option>
                                                ))
                                            }
                                        </select>
                                        <input type='text' className='form-control' placeholder='Хайх утга' name='search' onChange={(e) => {
                                            setFilter(e.target.value);
                                        }}
                                        value={filter || ''} />
                                        <div className='input-group-append'>
                                            <button className='btn btn-primary' onClick={handleSearch} type='button'><FaSearch /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={list}
                                    progressPending={loading}
                                    pagination={[50,100,200]}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={PAGINATION_OPTION}
                                />
                    
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {/* <div className="row mb-3 mt-4">
                        <div className="col-12">
                            <div className="text-center">
                                <button className="btn btn-outline-primary btn-sm" type="button">
                                    <span className="spinner-border spinner-border-sm me-1" role="status"
                                        aria-hidden="true"></span>
                                    Load More
                                </button>
                            </div>
                        </div> 
                    </div> */}
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
