import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getNews, getComments, saveComment, getInvestment } from '../../services/data.service'
import {decode} from 'html-entities';
import parse from "html-react-parser";
import { Image } from 'primereact/image';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import moment from 'moment'
import { NEWS_IMAGE_URL } from '../../config/Utilities';
import { FiCornerDownLeft } from "react-icons/fi";


export default function ShowInvestment() {
  
    const { id } = useParams();
    const [news, setNews] = useState({});


    useEffect(() => {
       
        getInvestment(id).then(r => setNews(r.data));
   
    },[]);


    return (
        <>
            <section className="hero-4 pb-5 pt-8 pt-lg-6 pb-sm-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Эхлэл</a></li>
                                    <li className="breadcrumb-item"><a href="/news">Төслүүд</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{news?.name ?? ""}</li>
                                </ol>
                            </nav>

                            <h1 className="hero-title mt-0">{news?.name ?? ""}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="position-relative pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            
                            <blockquote className="blockquote p-4 my-4 bg-light">
                                <p className="">
                                    {news?.summary}
                                </p>
                                {/* <footer className="blockquote-footer mt-0">
                                    <small className="fs-13">Christian Hall</small>
                                </footer> */}
                            </blockquote>

                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    {parse(decode(news?.description))}
                                </div>
                            </div>


                            <div data-toggle="image-gallery" data-delegate="a" data-type="image" data-enable-gallery="true" className="mt-4">
                                <div className="row">
                                   {
                                        news?.investment_images?.map((m) => (
                                            <div className="col-md-3">
                                                <a href={NEWS_IMAGE_URL+m.image_source}
                                                    data-title="Office Desks">
                                                    <div className="card shadow rounded-sm">
                                                        <div className="card-body p-1">
                                                            <img src={NEWS_IMAGE_URL+m.image_source} alt="" className="img-fluid rounded-sm" />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                   }

                                </div>
                            </div>
                     
                        </div>
                    </div>
                </div>
            </section>

        </>

        
    );
  
}
