
export const type = {
    TOGGLE_MODAL: "TOGGLE_MODAL",
    TOGGLE_UUIDMODAL: "TOGGLE_UUIDMODAL",
    TOGGLE_IMAGE: "TOGGLE_IMAGE",
    EDIT_DATA: "EDIT_DATA",
    REFRESH: "REFRESH",
};

export const helperReducer = (state, action) => {
    switch(action.type){
        case type.EDIT_DATA:
            return {
                ...state,
                isEditing: !state.isEditing
            };
        case type.TOGGLE_MODAL:
            return {
              ...state,
              showModal: !state.showModal
            };
        case type.TOGGLE_UUIDMODAL:
            return {
                ...state,
                showUUIDModal: !state.showUUIDModal
            };
        case type.TOGGLE_IMAGE:
            return{
                ...state,
                showImage: !state.showImage
            };

        default:
            return state;
    }
}