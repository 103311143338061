// let categories = localStorage.getItem("categories")
//   ? JSON.parse(localStorage.getItem("categories"))
//   : "";
export const type = {
    FETCH_LIST: "FETCH_LIST",
    FETCH_CATEGORIES: "FETCH_CATEGORIES",
    APPEND_LIST: "APPEND_LIST",
    REFRESH: "REFRESH",
    SET_CATEGORY: "SET_CATEGORY"
};

export const newsReducer = (state, action) => {
    switch(action.type){
        case type.FETCH_LIST:
            return {
                ...state,
                newsList: action.payload
            };
        case type.APPEND_LIST:
            return{
                ...state,
                newsList: [...state.newsList, ...action.payload]
            }
        case type.FETCH_CATEGORIES:
            localStorage.setItem('categories', JSON.stringify(action.payload));
            return{
                ...state,
                categories: action.payload
            };
        case type.REFRESH:
            return{
                ...state,
                refresh: state.refresh+1
            }
        case type.SET_CATEGORY:
            return{
                ...state,
                category: action.payload
            }
        
        default:
            return state;
    }
}