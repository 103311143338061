import React, { useContext, useReducer , useEffect } from "react"
import { type, newsReducer } from "../hooks/NewsReducer";
import { fetchCategories } from "../services/data.service";

let categories = localStorage.getItem("categories")
  ? JSON.parse(localStorage.getItem("categories"))
  : "";

const initialState = {
    categories: [] || categories,
    newsList: [],
    refresh: 0,
    category: "0"
};

const context = React.createContext();

export function useNewsContext(){
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error("Context error");
    }
    return ctx;
}

const NewsContext = ({children}) => {
    const [state, dispatch] = useReducer(newsReducer, initialState);

    useEffect(() => {

        if(initialState.categories === null || initialState.categories === undefined || initialState.categories.length === 0)
        {
            fetchCategories().then(r => {
              dispatch({
                    type: type.FETCH_CATEGORIES,
                    payload: r.data
                });
            });
        }
        
    }, [dispatch]);

    return (
        <context.Provider value={{
            stateNews: state,
            type: type,
            dispatchNews: dispatch
          }}>
            {children}
        </context.Provider>
    );
}

export default React.memo(NewsContext);

