import React, { useContext, useReducer } from "react"
import { type, helperReducer } from "../hooks/HelperReducer";

const initialState = {
  isEditing: false,
  showModal: false,
  showUUIDModal: false,
  showImage: false,
};


const context = React.createContext();

export const useHelperContext = () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error("Context error");
    }
    return ctx;
}

const HelperContext = ({children})=>{
    const [state, dispatch] = useReducer(helperReducer, initialState);

    return (
        <context.Provider value={{
            stateHelper: state,
            type: type,
            dispatchHelper: dispatch
          }}>
            {children}
        </context.Provider>
    );
}

export default React.memo(HelperContext);
