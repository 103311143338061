import React, { useEffect, useState } from 'react'
import { getMeta } from '../../services/data.service'
import {decode} from 'html-entities';
import parse from "html-react-parser";
import './About.css'

function About() {

    const [item, setItem] = useState({})

    useEffect(() => {
        getMeta("about").then((r) => {
            setItem(r.data)
        })
    },[])
  return (
    <>


        <section className="position-relative pt-1 pb-4">
            <div className="container">
            <div className="row justify-content-center">
                <div className="col text-center">
                    <h1 className="display-5 fw-semibold mt-3">Бидний тухай</h1>
                </div>
            </div>
            <div className="row maindesc" data-aos="fade-up">
                <div className="col-md-12">
                    {parse(decode(item?.description))}
                </div>
            </div>
        </div>
    </section>


    {/* <section className="py-5 mb-xl-5 mb-lg-4 position-relative" data-aos="fade-up">
        <div className="container">
            <div className="row align-items-center mt-5">
                <div className="col-lg-5">
                    <h1 className="display-5 fw-semibold">Build amazing things together</h1>
                    <p className="text-muted my-4">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium totam rem aperiam beatae vitae dicta sunt explicabo.</p>

                    <p className="text-muted my-4">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium totam rem aperiam beatae vitae dicta sunt explicabo.</p>
                    
                    <a href="#" className="h6 text-primary">Learn more <i className="ms-2 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
                <div className="col-lg-6 offset-lg-1">
                    <div className="img-content2 position-relative mt-4 mt-lg-0">
                        <div className="img-up mb-lg-0 mb-6">
                            <img src="./assets/images/photos/3.jpg" alt="app img" className="img-fluid d-block shadow" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    </>
  )
}

export default About