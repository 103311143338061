import React, { useEffect, useState } from 'react'
import { NEWS_IMAGE_URL } from '../../config/Utilities'
import { fetchMember, fetchMemberTypes } from '../../services/data.service'
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import '../news/News.css'

function Member() {


    const [list, setList] = useState([])
    const [visibleForm, setVisibleForm] = useState(false);
    const [blocked, setBlocked] = useState(false)
    const [item, setItem] = useState({})
    const [types, setTypes] = useState([])

    useEffect(() => {
        fetchMember("").then((r) => {
            setList(r.data)
        })
        fetchMemberTypes().then((r) => {
            setTypes(r.data)
        })
    }, [])

  return (
    <>
    <Dialog header="Дэлгэрэнгүй мэдээлэл" visible={visibleForm} style={{ width: '90vw' }} onHide={() => setVisibleForm(false)}>
          <BlockUI blocked={blocked}>
            <div className='row'>
                <div className='col-md-3'> 
                    <div className='news-thumbnail'>
                        <img src={item.image_source != null ? NEWS_IMAGE_URL+item.image_source : "/assets/images/avatars/img-2.jpg"} alt="crypto"
                            className="img-fluid shadow rounded img-thumb" />

                            <div className="d-flex align-items-center mb-4 pb-md-3">
                                <div className="flex-grow-1 text-center">
                                    <h3 className="mt-0 mb-1 fw-medium" >{item.first_name + " " + item.last_name}</h3>
                                    <p className="text-muted fw-medium mb-0">{item.position}</p>
                                </div>
                            </div>
                    </div>
                </div>
                <div className='col-md-9'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <h5>Ажлын туршлага</h5>
                    {
                        item.member_experiences?.map((exp, index) => (
                            <div className="row align-items-center justify-content-sm-between" key={"exp"+index}>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <label className="form-check-label" >
                                            {exp.date_range + ", " + exp.organization + ", " + exp.position}
                                        </label>
                                    </div> 
                                </div> 
                            </div>
                        ))
                        }
                    </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h5>Боловсрол</h5>
                        {
                            item.member_educations?.map((edu, index) => (
                                <div className="row align-items-center justify-content-sm-between" key={"edu"+index}>
                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <label className="form-check-label" >
                                                {edu.date_range + ", " + edu.location + ", " + edu.school + ", " + edu.degree}
                                            </label>
                                        </div> 
                                    </div> 
                                </div>
                            ))
                            }
                        </div>
                        </div>
                </div>
            </div>
          </BlockUI>
      </Dialog>


    <section className="pb-5 pt-6 mt-4 position-relative"  data-aos="fade-up">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col text-center">
                    <span className="badge rounded-pill badge-soft-info px-2 py-1">Зөвлөлийн бүрэлдэхүүн</span>
                    <h1 className="display-5 fw-medium">Сүхбаатар аймгийн наран сум</h1>
                    <p className="text-muted mx-auto">
                       <span className="text-dark fw-bold">Сүхбаатар аймгийн Наран сумын</span> нутгийн зөвлөл нь {list.length} гишүүнтэй.</p>
                </div>
            </div>
            {
                types.map((m) => (
                    <div className="row mt-5" key={m.uuid}>
                        <h3>{m.title}</h3><hr/>
                        {
                            list.filter((r) => r.type_id === m.uuid).map((l, index) => (
                                <div className="col-lg-4 col-md-6" key={l.uuid}>
                                    <div className="d-flex align-items-center mb-4 pb-md-3">
                                        <img src={l.image_source != null ? NEWS_IMAGE_URL+l.image_source : "/assets/images/avatars/img-1.jpg"} alt={l.first_name}
                                            className="img-fluid avatar-md d-block rounded me-3" />
                                        <div className="flex-grow-1">
                                            <h5 className="mt-0 mb-1 fw-medium" onClick={() => {setVisibleForm(true); setItem(l); }} >{l.first_name + " " + l.last_name}</h5>
                                            <p className="text-muted fw-medium mb-0">{l.position}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
        
                    </div>
                ))
            }
        </div>
    </section>
    </>
  )
}

export default Member