import React from 'react';
import ReactDOM, { hydrateRoot} from 'react-dom/client';
import './index.css';
import App from './App';


//hydrateRoot(document.getElementById('root'), <App />)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

