

import React, { useEffect, useState, useMemo }  from 'react'
import { deleteSurvey, fetchSurveyPagination } from '../../../services/data.service';
import DataTable from "react-data-table-component";
import { PAGINATION_OPTION } from '../../../config/Utilities';
import { FaEdit, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from 'react-toastify';

export default function SurveyList() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [filter, setFilter] = useState('')
    const navigate = useNavigate();

    useEffect(() => {

        fetchList(1)    

    },[])

    const fetchList = async (page, size = perPage) => {
        setLoading(true);
    
        fetchSurveyPagination(page, size, filter)
        .then((r) => {
            console.log(r)
            setList(r.data.list);
            setTotalRows(r.data.totalItems);
            setLoading(false);
        })

        
    };


    const handleDelete = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h2>НИЙТЛЭЛ УСТГАХ</h2>
                    <p>ТА ЭНЭ ҮЙЛДЛИЙГ ХИЙХИЙГ ЗӨВШӨӨРЧ БАЙНА УУ?</p>
                    <button className='btn-dismiss' onClick={onClose}>Буцах</button>
                    <button className='btn-confirm'
                      onClick={() => {
                        deleteSurvey(id)
                        .then((res)=>{
                            if(res.success){
                                setList(list.filter(item => item.uuid !== id));
                                toast.success(res.message);
                            }else{
                                toast.error(res.message);
                            }
                        });
                        onClose();
                      }}
                    >
                      Тийм
                    </button>
                  </div>
                );
            }
        });
        
    }

    const buttonRemoveTemplate = (row) => {
        return <button type='button' className="btn btn-sm btn-danger" onClick={() => {handleDelete(row.uuid)}}><FaTrash /></button>
    }

    const columns = useMemo(() => [
        {
            name: "Овог, нэр",
            selector: row => row.first_name + " " + row.last_name,
            sortable: true
        },
        {
            name: "Утас",
            selector: row => row.phone_number,
            sortable: true
        },
        {
          name: "Мэргэжил",
          selector: row => row.profession,
          sortable: true
        },
        {
          name: "Эрхэлж буй ажил",
          selector: row => row.job,
          sortable: true
        },
        {
            name: "Устгах",
            cell: row => buttonRemoveTemplate(row)
        }
      ])

      const handlePageChange = page => {
        fetchList(page);
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = async (newPerPage, page) => {
        fetchList(page, newPerPage);
        setPerPage(newPerPage);
      };

      const handleSearch = () => {

        setCurrentPage(1)
        fetchList(1)
    }


  return (
    <>
        <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <div className='container'>
        <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col">
                            <h4 className="mb-3 mt-0 fs-16">Судалгааны жагсаалт</h4>
                        </div>
                    </div>

     
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className='input-group'>
   
                                        <input type='text' className='form-control' placeholder='Хайх утга' name='search' onChange={(e) => {
                                            setFilter(e.target.value);
                                        }}
                                        value={filter || ''} />
                                        <div className='input-group-append'>
                                            <button className='btn btn-primary' onClick={handleSearch} type='button'><FaSearch /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={list}
                                    progressPending={loading}
                                    pagination={[50,100,200]}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={PAGINATION_OPTION}
                                />
                    
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {/* <div className="row mb-3 mt-4">
                        <div className="col-12">
                            <div className="text-center">
                                <button className="btn btn-outline-primary btn-sm" type="button">
                                    <span className="spinner-border spinner-border-sm me-1" role="status"
                                        aria-hidden="true"></span>
                                    Load More
                                </button>
                            </div>
                        </div> 
                    </div> */}
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
