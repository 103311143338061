


import React, { useEffect, useState } from 'react'
import { getInvestmentSummary } from '../../services/data.service'

function Statistic() {

    const [total, setTotal] = useState(0)
    const [budget, setBudget] = useState(0)
    const [progress, setProgress] = useState(0)

    useEffect(() => {

        getInvestmentSummary().then((r) => {
            setTotal(r.resp[0].total)
            setBudget(r.resp[0].budget)
            setProgress(r.resp[0].avg)
        })

    },[])

  return (
    <>
        <section className="section pt-8 pb-6 bg-gradient3 position-relative" data-aos="fade-up">
        <div className="divider top d-none d-sm-block"></div>
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <span className="badge rounded-pill badge-soft-primary px-2 py-1">Үзүүлэлт</span>
                    <h1 className="display-5 fw-medium">Төслүүдийн явц</h1>
                    <p className="text-muted mx-auto"></p>
                </div>
            </div>
            <div className="row mt-5 text-center">
                <div className="col-4 col-md-4 mb-4 mb-sm-0">
                    <div className="display-4 fw-normal">
                        ₮ { Intl.NumberFormat().format(budget) }
                        {/* <span data-toggle="counter" data-from="0" data-to={budget} data-decimals="0" data-duration="5" data-options='{}'></span> */}
                    </div>
                    <p className="mt-2 mb-0 fw-semibold"></p>
                    <p>Төсөл, арга хэмжээний нийт дүн</p>
                </div>

                <div className="col-4 col-md-4 mb-4 mb-sm-0">
                    <div className="display-4 fw-normal">{total}</div>
                    <p className="mt-2 mb-0 fw-semibold"></p>
                    <p>Төсөл, арга хэмжээний тоо</p>
                </div>

                <div className="col-4 col-md-4 mb-4 mb-sm-0">
                    <div className="display-4 fw-normal">{progress}%</div>
                    <p className="mt-2 mb-0 fw-semibold"></p>
                    <p>Төсөл, арга хэмжээний гүйцэтгэл</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Statistic