import React, { useEffect, useRef, useState } from 'react'
import { useAuthState } from '../../context/AuthContext'
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from 'formik';
import { getInfo, updateAvatar, updateInfo, updatePassword } from '../../services/data.service';
import { toast } from 'react-toastify';
import { NEWS_IMAGE_URL } from '../../config/Utilities';

function Profile() {

  const { state, dispatch } = useAuthState();
  const thumbnailRef = useRef(null)
  const [user, setUser] = useState({})

  const formik = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            phone: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.fname) {
            errors.fname = 'Эцэг/эхийн нэр оруулна ууу.';
            }

            if (!data.lname) {
                errors.lname = 'Өөрийн нэр оруулна уу.';
            }

            if (!data.phone) {
                errors.phone = 'Утасны дугаар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data
            console.log(data)
            const formData = new FormData();
            formData.append("fname", data.fname);
            formData.append("lname", data.lname);
            formData.append("phone", data.phone);

            updateInfo(formData)
            .then((res)=>{
                if(res.success){
                    setUser(res.data)
                    dispatch({type:"update", payload: res.data});
                    toast.success(res.message);
                }else{
                    toast.error(res.message);
                }
            });
            
        }
    });

    const formikPassword = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            passwordConfirm: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.oldPassword) {
                errors.oldPassword = 'Одоогийн нууц үг оруулна ууу.';
            }

            if (!data.password) {
                errors.password = 'Шинэ нууц үг оруулна уу.';
            }

            if (!data.passwordConfirm) {
                errors.passwordConfirm = 'Баталгаажууулах нууц үг оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data
            console.log(data)
            const formData = new FormData();
            formData.append("oldPassword", data.oldPassword);
            formData.append("password", data.password);
            formData.append("passwordConfirm", data.passwordConfirm);

            updatePassword(formData)
            .then((res)=>{
                if(res.success){
                    
                    toast.success(res.message);
                }else{
                    toast.error(res.message);
                }
            });
            
        }
    });


    useEffect(() => {

        getInfo(state?.user.uuid).then((r) => {
            setUser(r.user)
            formik.setFieldValue('fname', r.user.first_name)
            formik.setFieldValue('lname', r.user.last_name);
            formik.setFieldValue('phone', r.user.phone_number);

        })


    },[])

    const handleThumbnail = (e) => {
        console.log(e.files[0])
        let data = new FormData();
        data.append("avatar", e.files[0])
    
        updateAvatar(data)
        .then((res)=>{
            if(res.success){
                setUser((prevState) => ({
                    ...prevState,
                    image_source:res.data
                  }));
                toast.success(res.message); 
                thumbnailRef.current.clear();
            }else{
                toast.error(res.message);
            }
        });
      }

  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <div className='container'>
               <div className="row">
                <div className="col-lg-12">
                    <div className='card'>
                      <div className='card-body'>
                      <h4 className="mt-0">Бүртгэлийн мэдээлэл</h4>

                      <h6 className="mt-4">Нүүр зураг</h6>
                          <div className="row align-items-center">
                              <div className="col-auto">
                                  <img src={NEWS_IMAGE_URL + user?.image_source} className="img-fluid avatar-md rounded-circle shadow" alt="..." />
                              </div>
                              <div className="col">
                                 {/*  <a href="#" className="btn btn-outline-primary btn-sm">Upload</a>
                                  <a href="#" className="btn btn-outline-danger btn-sm ms-2">Remove</a> */}
                                  <FileUpload mode="basic" name="demo[]" ref={thumbnailRef} onSelect={handleThumbnail} className='mt-2'
                                        customUpload accept="image/*" maxFileSize={10000000} chooseLabel='Зураг солих' />
                              </div>
                          </div>
                        

                          <hr className="my-4" />

                          <div className="row align-items-center">
                              <div className="col-lg-6">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Овог<small>*</small></label>
                                        <input type="text" className="form-control"  id="fname" placeholder="Овог"
                                            name="fname" value={formik.values.fname}
                                            onChange={(e) => {
                                                formik.setFieldValue('fname', e.target.value);
                                            }} />
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label htmlFor="display_name" className="form-label">Нэр</label>
                                        <input type="text" className="form-control" id="lname"
                                            aria-describedby="display_name"
                                            placeholder="Нэр" name="lname"
                                            value={formik.values.lname}
                                            onChange={(e) => {
                                                formik.setFieldValue('lname', e.target.value);
                                            }}  />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Утас<small>*</small></label>
                                        <input type="text" className="form-control" id="phone" placeholder="Утас"
                                            name="phone" value={formik.values.phone}
                                            onChange={(e) => {
                                                formik.setFieldValue('phone', e.target.value);
                                            }}  />
                                    </div>

                                
                                    <button type="submit" className="btn btn-primary">Хадгалах</button>
                                </form>
                                 
                             
                              </div>

                              <div className="col-lg-6">
                                <form onSubmit={formikPassword.handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="display_name" className="form-label">Одоогийн нууц үг</label>
                                        <input type="password" className="form-control" id="oldPassword"
                                            aria-describedby="display_name"
                                            placeholder="Одоогийн нууц үг" name="oldPassword"
                                            value={formikPassword.values.oldPassword}
                                            onChange={(e) => {
                                                formikPassword.setFieldValue('oldPassword', e.target.value);
                                            }}  />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="display_name" className="form-label">Шинэ нууц үг</label>
                                        <input type="password" className="form-control" id="password"
                                            aria-describedby="display_name"
                                            placeholder="Шинэ нууц үг" name="password"
                                            value={formikPassword.values.password}
                                            onChange={(e) => {
                                                formikPassword.setFieldValue('password', e.target.value);
                                            }}  />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="display_name" className="form-label">Баталгаажуулах нууц үг</label>
                                        <input type="password" className="form-control" id="passwordConfirm"
                                            aria-describedby="display_name"
                                            placeholder="Баталгаажуулах нууц үг" name="passwordConfirm"
                                            value={formikPassword.values.passwordConfirm}
                                            onChange={(e) => {
                                                formikPassword.setFieldValue('passwordConfirm', e.target.value);
                                            }}  />
                                    </div>
                                    <button type="submit" className="btn btn-outline-primary">Шинэчлэх</button>
                                </form>
                                  
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    
    </>
  )
}

export default Profile