import React, { Component } from 'react'
import { useAuthState } from '../../../context/AuthContext'
import { useNavigate } from 'react-router-dom';
import { ACCESS_LEVELS, CHECK_PERMISSION, NEWS_IMAGE_URL } from '../../../config/Utilities';

function MenuNav() {


    const { state, dispatch } = useAuthState();
    const navigate = useNavigate();
    const logOut = () => {
        dispatch({type: "logout"});
        navigate("/");
    }

    return (
        <>
            <header>
              <nav className="navbar navbar-expand-lg topnav-menu navbar-light zindex-10" >
                <div className="container">
                  <a className="navbar-brand logo" href="/">
                    <img src="/assets/images/logo1.png" height="30" className="align-top logo-dark" alt="" />
                    <img src="/assets/images/logo1-light.png" height="30" className="align-top logo-light" alt="" />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content"
                    aria-controls="topnav-menu-content" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
    
                  <div className="collapse navbar-collapse" id="topnav-menu-content">
                    <ul className="navbar-nav align-items-lg-center d-flex me-auto">
                      
                    </ul>
    
                    
                      <ul className="navbar-nav align-items-lg-center ">
                          <li className="nav-item">
                              <a className="nav-link" href="/">Эхлэл</a>
                          </li>
                          <li className="nav-item dropdown">
                              <a className="nav-link dropdown-toggle" href="#" id="navbarDocs" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Бусад<i data-feather="chevron-down" className="d-inline-block icon icon-xxs ms-1 mt-lg-0 mt-1"></i>
                              </a>
                              <div className="dropdown-menu" aria-labelledby="navbarDocs">
                              {
                                (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator])) 
                                ? 
                                <>
                                    <ul className="nav">
                                      <li className="nav-item">
                                          <a className="nav-link" href="/h/meta" aria-labelledby="navbarDocs">Ерөнхий мэдээлэл</a>
                                      </li>
                                      <li className="nav-item">
                                          <a className="nav-link" href="/h/member" aria-labelledby="navbarDocs">Нутгийн зөвлөлийн гишүүд</a>
                                      </li>
                                      <li className="nav-item">
                                          <hr className="my-2" />
                                      </li>
                                      
                                      <li className="nav-item">
                                          <a className="nav-link" href="/h/survey" aria-labelledby="navbarDocs">Судалгаа</a>
                                      </li>
                                      <li className="nav-item">
                                          <a className="nav-link" href="/h/category" aria-labelledby="navbarDocs">Мэдээний ангилал</a>
                                      </li>
                                  </ul>
                                </>
                                : 
                                "" 
                                }
                                  
                              </div>
                          </li>
                          {
                            (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator])) 
                            ? 
                            <>
                                <li className="nav-item">
                                    <a className="nav-link" href="/h/investment" aria-labelledby="navbarDocs">Төслүүд</a>
                                </li>
                            </>
                            : 
                            "" 
                            }
                          {
                            (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator, ACCESS_LEVELS.Publisher])) 
                            ? 
                            <>
                                <li className="nav-item">
                                    <a className="nav-link" href="/h/news">Мэдээ, мэдээлэл</a>
                                </li>
                            </>
                            : 
                            "" 
                            }
                          
                          {
                            (CHECK_PERMISSION([ACCESS_LEVELS.Administrator, ACCESS_LEVELS.Moderator])) 
                            ? 
                            <>
                                <li className="nav-item">
                                    <a className="nav-link" href="/h/user">Хэрэглэгчид</a>
                                </li>
                            </>
                            : 
                            "" 
                            }
                          
                            {
                                (state.user) ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle py-0" href="#" id="user" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={state.user?.image_source != null ? NEWS_IMAGE_URL+state.user?.image_source : "/assets/images/avatars/img-8.jpg"} className="avatar avatar-xs rounded-circle me-2" alt="" />
                                        </div>
                                        <div className="flex-grow-1 ms-1 lh-base">
                                        <span className="fw-semibold fs-13 d-block line-height-normal">{state.user?.first_name + " " + state.user?.last_name}</span>
                                        <span className="text-muted fs-13"></span>
                                        </div>
                                    </div>
                                    </a>
                                
                                    <div className="dropdown-menu p-2" aria-labelledby="user">
                                
                                    <a className="dropdown-item p-2" href="/h/profile">
                                        <i className="icon icon-xxs me-1 icon-dual" data-feather="user"></i>
                                        Бүртгэл
                                    </a>
                                    <a className="dropdown-item p-2" href="/h/profile">
                                        <i className="icon icon-xxs me-1 icon-dual" data-feather="aperture"></i>
                                        Support
                                    </a>
                    
                                    <div className="dropdown-divider"></div>
                    
                    
                                    <a className="dropdown-item p-2" href="#" onClick={logOut}>
                                        <i className="icon icon-xxs me-1 icon-dual" data-feather="unlock"></i>
                                        Гарах
                                    </a>
                                
                                    </div>
                                </li>
                                :
                                <li className="nav-item ms-2">
                                    <a className="btn btn-outline-primary btn-sm" href="/login">Нэвтрэх</a>
                                </li>
                          
                                
                            }
                          
                          
    
                      </ul>
                    
    
                    
                  </div>
                </div>
              </nav>
    
            </header>
        </>
      )
}


export default MenuNav