

import React from 'react'

function Banner() {
  return (
    <>
        
        <section className="position-relative overflow-hidden  pb-2 pt-7 pt-sm-3 pb-sm-7">
            <div className="container">
                <div className="row align-items-center ">
                    <div className="col-lg-12 col-md-12 order-2 order-sm-1">
                        <div className="img-container" data-aos="fade-right">
                            
                            <div className="card shadow rounded-sm">
                                        <div className="card-body p-1">
                                        <img src="./assets/images/sbnaranbanner1.jpeg" alt="" className="img-fluid rounded-sm" />
                                        </div>
                                    </div>
                        </div>
                    </div>

                   {/*  <div className="col-lg-5 offset-lg-1 order-sm-2" data-aos="fade-left">
                        <h1 className="mt-0 mb-4 pb-2 hero-title">
                            Boost your <span className="highlight highlight-success d-inline-block">sales</span> with ease
                        </h1>

                        <p className="fs-17 text-muted">Explore a fully automated RIO driven digital marketing platform.</p>

                        <div className="pt-4 pb-3">
                            <div className="row g-2 text-start">
                                <div className="col-sm-auto">
                                    <label className="visually-hidden" htmlFor="email">email</label>
                                    <div className="form-control-with-hint">
                                        <input type="email" className="form-control" id="email" placeholder="Enter Your Email" />
                                        <span className="form-control-feedback uil uil-mail fs-18"></span>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <button className="btn btn-primary mt-1 mt-sm-0">Start Free Trial</button>
                                </div>
                            </div>
                            <p className="text-muted fs-13 text-start mt-1">* No Credit Card Required</p>
                        </div>
                    </div> */}
            
                </div>
            </div>
            <div className="shape bottom">
                <svg width="1440px" height="40px" viewBox="0 0 1440 40" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="shape-b" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="curve" fill="#fff">
                            <path d="M0,30.013 C239.659,10.004 479.143,0 718.453,0 C957.763,0 1198.28,10.004 1440,30.013 L1440,40 L0,40 L0,30.013 Z" id="Path"></path>
                        </g>
                    </g>
                </svg>
            </div>
        </section>
    </>
  )
}

export default Banner