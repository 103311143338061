import React, { useRef, useState } from 'react'
import { useNewsContext } from '../../../context/NewsContext';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import {encode} from 'html-entities';
import { toast } from 'react-toastify';
import { QUILL_TOOLBAR } from '../../../config/Utilities';
import { addNews } from '../../../services/data.service';
import { useNavigate} from "react-router-dom";
import { FcCheckmark } from 'react-icons/fc';

function NewsAdd() {

  const {stateNews, type, dispatchNews} = useNewsContext();
    const [newsThumbnail, setThumbnail] = useState(null);
    const [newsImages, setImages] = useState([]);
    let navigate = useNavigate();
    const thumbRef = useRef(null);
    const fileRef = useRef(null);

    const renderHeader = () => {
        return QUILL_TOOLBAR()
    }

    const header = renderHeader();

    const formik = useFormik({
        initialValues: {
            title: '',
            summary: '',
            description: '',
            category: ''
            //isFeatured: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.category) {
                errors.category = 'Мэдээний төрөл сонгоно уу.';
            }

            if (!data.title) {
                errors.title = 'Мэдээний гарчиг оруулна уу.';
            }

            if (!data.summary) {
                errors.summary = 'Хураангуй тайлбар оруулна уу.';
            }

            if (!data.description) {
                errors.description = 'Дэлгэрэнгүй тайлбар оруулна уу.';
            }

            return errors;
        },
        onSubmit: (data) => { //data

            const formData = new FormData();
            formData.append("description", encode(data.description));
            formData.append("category", data.category);
            //formData.append("isFeatured", data.isFeatured);
            formData.append("title", data.title);
            formData.append("summary", data.summary);

            formData.append('thumbnail', newsThumbnail);

            console.log(newsImages)
            newsImages.forEach(file => {
                console.log(file)
                if(file !== undefined)
                {
                    formData.append('images', file);
                }
            });

            addNews(formData)
            .then((res)=>{
                if(res.success){
                    toast.success(res.message);
                    formik.resetForm();
                    setThumbnail(null);
                    setImages([]);
                    thumbRef.current.clear();
                    fileRef.current.clear();
                }else{
                    toast.error(res.message);
                }
            });
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleImages = (e) => {
        const files = e.files;
        console.log(files.length)

        for(let i = 0; i < files.length; i++){
            console.log(files[i])
           
        }

        setImages(curr => [...curr,...files])

        // Object.keys(files).forEach((key) => {
        //     _totalSize += files[key].size || 0;
        // });
    };

    const handleThumbnail = async(e) => {
        console.log(e.files[0])
        setThumbnail(e.files[0]);
        console.log(newsThumbnail)
        
    };



  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
          <div className='container'>
          <div className="row">
                <div className="col">
                    <h4 className="mb-3 mt-0 fs-16">Мэдээ шинээр бүртгэх</h4>
                </div>
                <div className="col-auto text-end">
                    <a onClick={() => navigate(-1)} className="fw-semibold text-primary fs-13">Жагсаалт руу буцах <i
                            className="ms-1 icon-xxs" data-feather="arrow-right"></i></a>
                </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                  <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                            <div className='form-group p-fluid'>
                                <label>Мэдээний төрөл</label>
                                <Dropdown value={formik.values.category} 
                                    onChange={(e) => {
                                        formik.setFieldValue('category', e.value);
                                    }}
                                options={stateNews.categories} optionLabel="title" optionValue='uuid'
                                    className={ 'w-full ' + classNames({ 'p-invalid': isFormFieldInvalid('category') })}
                                        placeholder="Мэдээний төрөл"  />

                                    {getFormErrorMessage('category')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Гарчиг</label>
                                <InputText
                                    id="title"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={(e) => {
                                        formik.setFieldValue('title', e.target.value);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('title') })}
                                />
                                
                         
                                {getFormErrorMessage('title')}
                            </div>
                            <div className="form-group p-fluid">
                                <label>Хураангуй</label>
                                <InputTextarea
                                    id="summary"
                                    name="summary"
                                    rows={4}
                                    cols={30}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('summary') })}
                                    value={formik.values.summary}
                                    onChange={(e) => {
                                        formik.setFieldValue('summary', e.target.value);
                                    }}
                                />
                                {getFormErrorMessage('summary')}
                            </div>
                            <div className='form-group mt-1'>
                                <label>Дэлгэрэнгүй тайлбар</label>
                                <Editor value={formik.values.description} headerTemplate={header} className={classNames({ 'p-invalid': isFormFieldInvalid('description') })}
                                onTextChange={(e) => formik.setFieldValue('description',e.htmlValue)} style={{ height: '320px' }} />
                                {getFormErrorMessage('description')}
                            </div>
                            
                            {/* <div className='form-group mt-2'>
                                <div className="flex align-items-center">
                                    <Checkbox onChange={e => formik.setFieldValue('isFeatured',e.checked)} checked={formik.values.isFeatured}/>
                                    <label htmlFor="ingredient1" className="ml-2">&nbsp;Онцлох мэдээ</label>
                                </div>
                            </div> */}
                            <div className='form-group mt-1'>
                                <FileUpload mode="basic" name="demo[]" ref={thumbRef} maxFileSize={10000000} chooseLabel="Нүүр зураг сонгох"  accept="image/*" customUpload onSelect={handleThumbnail}  />
                            </div>
                            <hr/>
                            <div className='form-group mt-1'>
                                <FileUpload name="demos[]"  multiple accept="image/*" 
                                    maxFileSize={10000000} customUpload onSelect={handleImages} ref={fileRef}
                                    emptyTemplate={<p className="m-0">Хуулах зурагнуудаа чирээд оруулна уу.</p>} />
                            </div>
                            <button className='btn btn-primary mt-2' type='submit'><FcCheckmark /> Нийтлэх</button>
                        </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default NewsAdd