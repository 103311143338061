import React, { createContext, useContext, useReducer  } from "react"
import authReducer, { initialState } from "../hooks/AuthReducer"

const AuthContext = createContext();

export function useAuthState(){
    const context = useContext(AuthContext);

    return context;
}

export default function AuthContextProvider({children}){
    const [state, dispatch] = useReducer(authReducer, initialState);

    return (
        <AuthContext.Provider value={{state,dispatch}}>
            {children}
        </AuthContext.Provider>
    );
}


