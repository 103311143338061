

import React, { useEffect } from 'react'
import { useAuthState } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom';
import { NEWS_IMAGE_URL } from '../../config/Utilities';

function Navbar() {

    const { state, dispatch } = useAuthState();
    const navigate = useNavigate();

    const logOut = () => {
        dispatch({type: "logout"});
        navigate("/");
    }


  return (
    <>
        <header>
          <nav className="navbar navbar-expand-lg topnav-menu navbar-light zindex-10" >
            <div className="container">
              <a className="navbar-brand logo" href="/">
                <img src="/assets/images/logo1.png" height="30" className="align-top logo-dark" alt="" />
                <img src="/assets/images/logo1-light.png" height="30" className="align-top logo-light" alt="" />
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content"
                aria-controls="topnav-menu-content" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="topnav-menu-content">
                <ul className="navbar-nav align-items-lg-center d-flex me-auto">
                  
                </ul>

                
                  <ul className="navbar-nav align-items-lg-center ">
                      <li className="nav-item">
                          <a className="nav-link" href="/">Эхлэл</a>
                      </li>
                      <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" id="navbarDocs" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Бидний тухай<i data-feather="chevron-down" className="d-inline-block icon icon-xxs ms-1 mt-lg-0 mt-1"></i>
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarDocs">
                              <ul className="nav">
                                  <li className="nav-item">
                                      <a className="nav-link" href="/about" aria-labelledby="navbarDocs">Бидний тухай</a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/member" aria-labelledby="navbarDocs">Нутгийн зөвлөлийн гишүүд</a>
                                  </li>
                                  <li className="nav-item">
                                      <hr className="my-2" />
                                  </li>
                              </ul>
                          </div>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/investment" aria-labelledby="navbarDocs">Төслүүд</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/news">Мэдээ, мэдээлэл</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/survey">Судалгаа</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/contact">Холбоо барих</a>
                      </li>
                      {/* <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" id="navbarPages" role="button" data-bs-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              Pages<i data-feather="chevron-down" className="d-inline-block icon icon-xxs ms-1 mt-lg-0 mt-1"></i>
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarPages">
                              <ul className="nav">
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" href="#" id="accountPages" role="button" data-bs-toggle="dropdown" aria-labelledby="navbarPages"
                                          aria-haspopup="true" aria-expanded="false">
                                          Account
                                          <div className="arrow"></div>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="accountPages">
                                          <ul className="nav">
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/account-login.html">Login</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/account-signup.html">SignUp</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/account-forget-password.html">Forget Password</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/account-confirm.html">Confirm Account</a>
                                              </li>
                                              <li className="nav-item">
                                                  <hr className="my-2" />
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/dashboard.html">Dashboard</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/account-settings.html">Settings</a>
                                              </li>
                                          </ul>
                                      </div>
                                  </li>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" href="#" id="blogPages" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  aria-labelledby="navbarPages">
                                          Blog
                                          <div className="arrow"></div>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="blogPages">
                                          <ul className="nav">
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/blog.html" aria-labelledby="blogPages">Blog</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/blog-post.html" aria-labelledby="blogPages">Blog Post</a>
                                              </li>
                                          </ul>
                                      </div>
                                  </li>
                                  <li className="nav-item">
                                      <hr className="my-2" />
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/company.html" aria-labelledby="navbarPages">Company</a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/contact.html" aria-labelledby="navbarPages">Contact</a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/career.html" aria-labelledby="navbarPages">Career</a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/pricing.html" aria-labelledby="navbarPages">Pricing</a>
                                  </li>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" href="#" id="portfolioPages" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-labelledby="navbarPages">
                                          Portfolio
                                          <div className="arrow"></div>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="portfolioPages">
                                          <ul className="nav">
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/portfolio-grid.html" aria-labelledby="portfolioPages">Portfolio Grid</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/portfolio-masonry.html" aria-labelledby="portfolioPages">Portfolio Masonry</a>
                                              </li>
                                              <li className="nav-item">
                                                  <a className="nav-link" href="/portfolio-item.html" aria-labelledby="portfolioPages">Portfolio Item</a>
                                              </li>
                                          </ul>
                                      </div>
                                  </li>
                                  <li className="nav-item">
                                      <hr className="my-2" />
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/help-desk.html" aria-labelledby="navbarPages">Help</a>
                                  </li>
                              </ul>
                          </div>
                      </li> */}

                      {/* <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" id="navbarDocs" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Docs<i data-feather="chevron-down" className="d-inline-block icon icon-xxs ms-1 mt-lg-0 mt-1"></i>
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarDocs">
                              <ul className="nav">
                                  <li className="nav-item">
                                      <a className="nav-link" href="/docs/index.html" aria-labelledby="navbarDocs">Getting Started</a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/docs/bootstrap.html" aria-labelledby="navbarDocs">Components</a>
                                  </li>
                                  <li className="nav-item">
                                      <hr className="my-2" />
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/docs/change-log.html" aria-labelledby="navbarDocs">Change Log</a>
                                  </li>
                              </ul>
                          </div>
                      </li> */}

                        {
                            (state.user) ?
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle py-0" href="#" id="user" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={state.user?.image_source != null ? NEWS_IMAGE_URL+state.user?.image_source : "/assets/images/avatars/img-8.jpg"} className="avatar avatar-xs rounded-circle me-2" alt="" />
                                        </div>
                                        <div className="flex-grow-1 ms-1 lh-base">
                                            <span className="fw-semibold fs-13 d-block line-height-normal">{state.user?.first_name + " " + state.user?.last_name}</span>
                                            <span className="text-muted fs-13"></span>
                                        </div>
                                    </div>
                                </a>

                                <div className="dropdown-menu p-2" aria-labelledby="user">
                            
                                    <a className="dropdown-item p-2" href="/h/profile">
                                        <i className="icon icon-xxs me-1 icon-dual" data-feather="user"></i>
                                        Бүртгэл
                                    </a>
                                    <a className="dropdown-item p-2" href="/h/profile">
                                        <i className="icon icon-xxs me-1 icon-dual" data-feather="aperture"></i>
                                        Support
                                    </a>
                    
                                    <div className="dropdown-divider"></div>
                    
                    
                                    <a className="dropdown-item p-2" href="#" onClick={logOut}>
                                        <i className="icon icon-xxs me-1 icon-dual" data-feather="unlock"></i>
                                        Гарах
                                    </a>
                            
                                </div>
                            </li>
                            :
                            <li className="nav-item ms-2">
                                <a className="btn btn-outline-primary btn-sm" href="/login">Нэвтрэх</a>
                            </li>
                            
                            
                        }
                      
                      

                  </ul>
                

                
              </div>
            </div>
          </nav>

        </header>
    </>
  )
}

export default Navbar